import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import formatUSD from '../../../utils/formatUSD';
import BackgroundDisplay from './BackgroundDisplay';

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.isLastItem ? '0' : '24px')};
`;

const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 125px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
`;

const ImageContent = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Price = styled.div`
  color: #4a4a4a;
  padding-top: 8px;
  font-size: 13px;
  text-align: center;
`;

const ImagePairColumn = styled.div`
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SingleImageColumn = styled.div`
  margin-right: 20px;
`;

// New styled component for personalization
const Personalization = styled.div`
  font-style: italic;
  font-size: 14px;
  margin-top: 8px; // Added some margin for separation
`;

const ChildItemBackgroundPose = ({
  item,
  isLastItem,
  shouldDisplayBackgroundPrice,
}) => {
  const { isGeskus, isPostEvent, studentPreviewImageUrl } = useSelector(
    (state) => state.additionalData,
  );

  const renderBackgrounds = (cartItem, shouldDisplayBackgroundPrice) =>
    cartItem.backgrounds.map((background, index) => {
      const pose = cartItem.poses?.[0];
      const poseUrl = pose?.selected_crop
        ? pose?.selected_crop?.url
        : pose?.url || studentPreviewImageUrl;
      return (
        <ImagePairColumn key={`item-background-${background.id}-${index}`}>
          <SingleImageColumn>
            <Image
              style={{
                backgroundImage: `url("${background.image_thumbnail}")`,
              }}
            >
              {(pose ||
                (isGeskus && isPostEvent && studentPreviewImageUrl)) && (
                <ImageContent src={poseUrl} />
              )}
            </Image>
            {(background?.price && background?.price) > 0 &&
              shouldDisplayBackgroundPrice && (
                <Price>{formatUSD(background.price)}</Price>
              )}
            <BackgroundDisplay index={index} cartItem={cartItem} />
          </SingleImageColumn>

          {/* {isGeskus && isPostEvent && studentPreviewImageUrl && (
            <SingleImageColumn>
              <Image
                style={{ backgroundImage: `url("${studentPreviewImageUrl}")` }}
              />
            </SingleImageColumn>
          )} */}
        </ImagePairColumn>
      );
    });

  const renderPosesOnly = (cartItem) => {
    if (cartItem.backgrounds.length > 0) {
      return null;
    }

    return cartItem.poses.map((_, index) => {
      const pose = cartItem.poses[0];
      const poseUrl = pose?.selected_crop ? pose.selected_crop.url : pose.url;
      return (
        <ImagePairColumn key={`item-background-${pose.id}-${index}`}>
          <SingleImageColumn>
            <Image style={{ backgroundImage: `url("${poseUrl}")` }} />
          </SingleImageColumn>
        </ImagePairColumn>
      );
    });
  };

  return (
    <RowContainer isLastItem={isLastItem}>
      {renderBackgrounds(item, shouldDisplayBackgroundPrice)}
      {renderPosesOnly(item)}
      {item.personalizationValue && (
        <Personalization>
          personalization: {item.personalizationValue}
        </Personalization>
      )}
    </RowContainer>
  );
};

export default ChildItemBackgroundPose;
