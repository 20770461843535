import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';

const BannerContainer = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 12px 20px;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const MessageContainer = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #856404;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
  line-height: 1;
  margin-left: 10px;
  &:hover {
    color: #533f03;
  }
`;

const Link = styled.a`
  color: #533f03;
  text-decoration: underline;
  display: inline-block;
  margin-top: 8px;
  &:hover {
    color: #533f03;
    text-decoration: none;
  }
`;

const UnsubscribeBanner = () => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [unsubscribeStatus, setUnsubscribeStatus] = useState({
    all_events: false,
    sms_unsubscribed: false,
    unsubscribed_from_all: false,
  });

  const email = useSelector((state) => state.parent && state.parent.email);

  useEffect(() => {
    const checkUnsubscribeStatus = async () => {
      if (!email) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `/api/v2/parents/unsubscribe-status?email=${encodeURIComponent(
            email,
          )}`,
        );
        setUnsubscribeStatus(response.data);
      } catch (error) {
        if (error.response) {
          console.error(
            'Error fetching unsubscribe status:',
            error.response.data,
          );
        }
      } finally {
        setLoading(false);
      }
    };

    checkUnsubscribeStatus();
  }, [email]);

  if (loading || !visible || !unsubscribeStatus.unsubscribed_from_all) {
    return null;
  }

  const getMessage = () => {
    if (unsubscribeStatus.all_events && unsubscribeStatus.sms_unsubscribed) {
      return "You're currently opted out of all email and SMS communications";
    }
    if (unsubscribeStatus.all_events) {
      return "You're currently opted out of all email communications";
    }
    if (unsubscribeStatus.sms_unsubscribed) {
      return "You're currently opted out of all SMS communications";
    }
    return '';
  };

  return (
    <BannerContainer>
      <MessageContainer>
        {getMessage()} and may miss important announcements about upcoming
        events.
        <br />
        <Link href="/my-account">
          Please visit your account page to adjust your preferences
        </Link>
      </MessageContainer>
      <CloseButton onClick={() => setVisible(false)} aria-label="Close">
        ×
      </CloseButton>
    </BannerContainer>
  );
};

export default UnsubscribeBanner;
