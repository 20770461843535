import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from 'axios';
import PropTypes from 'prop-types';
import { RESET_SUCCESS_MESSAGE } from '../../actions/action_parent';

const LeftColumn = styled.div`
  padding: 0 15px;
`;
const RightColumn = styled.div`
  padding: 0 15px;
  float: right;
`;
const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Header1 = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #479de6;
`;

const EditLinkStyled = styled(Link)`
  text-decoration: underline;
  color: #479de6;
  cursor: pointer;
  margin-top: 16px;
`;

const SuccessAlert = styled.div`
  margin-top: 16px;
`;

const ResubscribeButton = styled.button`
  background: none;
  border: none;
  color: #479de6;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font: inherit;
`;

const Label = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
`;

const EmailPreferences = ({ email }) => {
  const [unsubscribeStatus, setUnsubscribeStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnsubscribeStatus = async () => {
      try {
        const response = await axios.get(
          `/api/v2/parents/unsubscribe-status?email=${encodeURIComponent(
            email,
          )}`,
        );
        setUnsubscribeStatus(response.data);
      } catch (err) {
        setError('Failed to load email preferences');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUnsubscribeStatus();
  }, [email]);

  const handleResubscribe = async (eventId = null) => {
    try {
      setLoading(true);
      await axios.post('/api/v2/parents/resubscribe-email', {
        email,
        eventId,
        all: !eventId,
      });

      // Refresh status after resubscribing
      const statusResponse = await axios.get(
        `/api/v2/parents/unsubscribe-status?email=${encodeURIComponent(email)}`,
      );
      setUnsubscribeStatus(statusResponse.data);
    } catch (err) {
      setError('Failed to resubscribe');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading email preferences...</p>;
  if (error) return <p className="text-danger">{error}</p>;
  if (!unsubscribeStatus) return null;

  let content;
  if (unsubscribeStatus.all_events) {
    content = (
      <div>
        <p>You are currently unsubscribed from all email communications.</p>
        <ResubscribeButton onClick={() => handleResubscribe()}>
          Resubscribe to all emails
        </ResubscribeButton>
      </div>
    );
  } else if (
    unsubscribeStatus.event_unsubscribes &&
    unsubscribeStatus.event_unsubscribes.length > 0
  ) {
    content = (
      <div>
        <p>You are unsubscribed from emails for the following events:</p>
        <ul>
          {unsubscribeStatus.event_unsubscribes.map((event) => (
            <li key={event.id}>
              {event.name}{' '}
              <ResubscribeButton onClick={() => handleResubscribe(event.id)}>
                Resubscribe
              </ResubscribeButton>
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    content = <p>You are currently subscribed to all email communications.</p>;
  }

  return (
    <div>
      <Header1>Email Preferences</Header1>
      <hr />
      {content}
    </div>
  );
};

EmailPreferences.propTypes = {
  email: PropTypes.string.isRequired,
};

const ParentMyAccount = () => {
  const dispatch = useDispatch();
  const parent = useSelector((state) => state.parent);
  const successMessage = useSelector((state) => state.parent.successMessage);

  const formattedPhoneNumber = parsePhoneNumberFromString(
    parent.smsPhone || parent.phone,
    'US',
  );

  useEffect(
    () => () => {
      dispatch({ type: RESET_SUCCESS_MESSAGE });
    },
    [successMessage],
  );

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Account Information</MainHeader>
        {successMessage && (
          <SuccessAlert className="alert alert-success">
            <p>
              {successMessage}{' '}
              <EditLinkStyled to="/dashboard">Start an order</EditLinkStyled>
            </p>
          </SuccessAlert>
        )}
        <Divider />
        <div className="row">
          <LeftColumn className="col-xs-12 col-md-7 col-lg-7">
            <Wrapper>
              <Header1>Profile and Contact</Header1>
              <hr />
              <div>
                <Label id="name-label">Name</Label>
                <p aria-labelledby="name-label">
                  {parent.firstName} {parent.lastName}
                </p>
                <EditLinkStyled to="/my-account/name">edit name</EditLinkStyled>
              </div>
              <hr />
              {parent.authProvider ? (
                <div>
                  <Label id="email-label">
                    Email (via{' '}
                    {parent.authProvider.charAt(0).toUpperCase() +
                      parent.authProvider.slice(1)}
                    )
                  </Label>
                  <p aria-labelledby="email-label">{parent.email}</p>
                </div>
              ) : (
                <div>
                  <Label id="email-label">Email</Label>
                  <p aria-labelledby="email-label">{parent.email}</p>
                  {parent.unconfirmedEmail ? (
                    <>
                      <hr />
                      <Label id="unconfirmed-email-label">
                        Awaiting confirmation
                      </Label>
                      <p aria-labelledby="unconfirmed-email-label">
                        {parent.unconfirmedEmail} (check your email for a link)
                      </p>
                      <EditLinkStyled to="/my-account/email">
                        edit email or resend confirmation
                      </EditLinkStyled>
                    </>
                  ) : (
                    <EditLinkStyled to="/my-account/email">
                      edit email
                    </EditLinkStyled>
                  )}
                  <hr />
                  <EditLinkStyled to="/my-account/change-password">
                    reset password
                  </EditLinkStyled>
                </div>
              )}
              <hr />
              <div>
                <Label id="phone-label">Phone</Label>
                <p aria-labelledby="phone-label">
                  {formattedPhoneNumber
                    ? formattedPhoneNumber.formatNational()
                    : 'Invalid Number'}
                </p>
                <EditLinkStyled to="/my-account/phone">
                  edit phone
                </EditLinkStyled>
              </div>
              <hr />
              <div>
                <Label id="address-label">Address</Label>
                <p aria-labelledby="address-label">
                  {parent.address.address1}
                  <br />
                  {parent.address.address2 && parent.address.address2}
                  {parent.address.address2 && <br />}
                  {parent.address.city}, {parent.address.state}{' '}
                  {parent.address.zipcode}
                </p>
                <EditLinkStyled to="/my-account/address">
                  edit address
                </EditLinkStyled>
              </div>
            </Wrapper>
          </LeftColumn>
          <RightColumn className="col-xs-12 col-md-5 col-lg-5">
            <Wrapper>
              <Header1>Students</Header1>
              <hr />
              <p>
                {parent.students.map((student) => (
                  <span key={`${student.id}`}>
                    {student.firstName} {student.lastName}
                    <br />
                  </span>
                ))}
              </p>
              <EditLinkStyled to="/my-account/add-student">
                add student
              </EditLinkStyled>
            </Wrapper>
            <Wrapper>
              <EmailPreferences email={parent.email} />
            </Wrapper>
          </RightColumn>
        </div>
      </div>
    </div>
  );
};

export default ParentMyAccount;
