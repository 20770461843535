import React from 'react';
import styled from 'styled-components';

const Display = styled.div`
  font-size: 11px;
  color: #4a4a4a;
  margin: 2px;
  max-width: 120px;
`;

const BackgroundDisplay = ({ cartItem, index }) => {
  const determineDisplayName = (name) => {
    // Remove underscores and replace them with spaces
    name = name.replace(/_/g, ' ');

    // Check if the name consists of only a number followed by a file extension
    if (/^\d+\.[^.]+$/.test(name)) {
      return name.split('.')[0]; // Keep only the number
    }

    // Remove file extensions (e.g., ".jpg", ".png")
    name = name.replace(/\.[^.]+$/, '');

    const words = name.split(' ');

    // If the first word is a number, remove it
    if (!isNaN(words[0])) {
      return words.slice(1).join(' ');
    }
    return name;
  };

  return (
    <Display>
      {cartItem?.backgrounds && (
        <div>
          <div>
            <span>
              <b>Background Name: </b>
            </span>
            <span>
              {cartItem?.backgrounds[index]?.display_name
                ? (() => {
                    let name = cartItem?.backgrounds[index]?.display_name;
                    return determineDisplayName(name);
                  })()
                : ''}
            </span>
          </div>
          <div>
            <span>
              <b>Code: </b>
            </span>
            <span>{cartItem?.backgrounds[index]?.background_code}</span>
          </div>
        </div>
      )}
      {cartItem?.backgroundSelections && (
        <div>
          <div>
            <span>
              <b>Background Name: </b>
            </span>
            <span>
              {cartItem?.backgroundSelections[index]?.display_name
                ? (() => {
                    let name =
                      cartItem.backgroundSelections[index].display_name;
                    return determineDisplayName(name);
                  })()
                : ''}
            </span>
          </div>
          <div>
            <span>
              <b>Code: </b>
            </span>
            <span>
              {cartItem?.backgroundSelections[index]?.background_code}
            </span>
          </div>
        </div>
      )}
    </Display>
  );
};
export default BackgroundDisplay;
