import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import formatUSD from '../../../utils/formatUSD';
import BackgroundDisplay from './BackgroundDisplay';

const RowContainer = styled.div`
  display: flex;
  flex-direction: column; // Changed to column to stack child elements vertically
`;

const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 125px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
`;

const ImageContent = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Name = styled.div`
  color: #4a4a4a;
  padding-top: 8px;
  font-size: 13px;
  text-align: left;
  padding-bottom: 8px;
`;

const Price = styled.div`
  color: #4a4a4a;
  padding-top: 8px;
  font-size: 13px;
  text-align: center;
`;

const ImagePairColumn = styled.div`
  display: flex;
  gap: 16px;
`;

const SingleImageColumn = styled.div`
  margin-right: 20px;
  max-width: 120px;
`;

const CartItemBackgroundsPoses = ({
  cartItem,
  orderAdditionalData,
  shouldDisplayBackgroundPrice,
  singleChargeForBackgroundUpgrades,
}) => {
  const { backgroundOptions } = useSelector((state) => state.shoot);
  const { isGeskus, isPostEvent, studentPreviewImageUrl } = orderAdditionalData;
  const { poses, isMultiConfig } = cartItem;
  const pose = poses?.length > 0 ? poses[0] : null;

  const findBackgroundImage = (jsonObj, imageId) => {
    for (const obj of jsonObj) {
      const image = obj.background_images.find((img) => img.id === imageId);
      if (image) {
        return image;
      }
    }
    return null;
  };

  const renderBackgrounds = () => {
    const { backgroundSelections } = cartItem;
    let hasDisplayedBackgroundPrice = false; // Track if we've displayed a price

    return cartItem.backgroundIds.map((backgroundId, index) => {
      const background = findBackgroundImage(backgroundOptions, backgroundId);

      let pose = null;
      let multiConfigChildProduct = null;

      if (cartItem && Array.isArray(poses) && poses.length > index) {
        pose = poses[index];
        multiConfigChildProduct = cartItem.multiConfigChildProducts?.[index];
      }

      if (!((pose && !isGeskus) || background)) {
        return null;
      }

      let backgroundPrice = 0;

      if (backgroundSelections && backgroundSelections.length > 0) {
        const selection = backgroundSelections.find(
          (selection) => selection.id === backgroundId,
        );

        if (selection) {
          backgroundPrice = selection.price;
        }
      }

      let studentPoseUrl = null;

      if (pose) {
        studentPoseUrl = poses[index]?.url;
      } else if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        studentPoseUrl = studentPreviewImageUrl;
      }

      const shouldShowPrice =
        shouldDisplayBackgroundPrice &&
        !hasDisplayedBackgroundPrice &&
        backgroundPrice > 0;

      if (shouldShowPrice && singleChargeForBackgroundUpgrades) {
        hasDisplayedBackgroundPrice = true; // Mark that we've displayed a price
      }

      return (
        <ImagePairColumn
          key={`cart-background-option-${backgroundId}-${index}`}
        >
          <SingleImageColumn>
            {multiConfigChildProduct && (
              <Name>{multiConfigChildProduct.productName}</Name>
            )}
            {/* {pose?.selected_crop?.name && (
              <Name>{pose.selected_crop.name}</Name>
            )} */}
            {pose?.selected_crop ? (
              <Image
                style={{
                  backgroundImage: `url("${background?.image_thumbnail}")`,
                }}
                onContextMenu={(event) => event.preventDefault()}
              >
                <ImageContent src={pose.selected_crop.url} />
              </Image>
            ) : (
              <Image
                style={{
                  backgroundImage: `url("${background?.image_thumbnail}")`,
                }}
                onContextMenu={(event) => event.preventDefault()}
              >
                {studentPoseUrl && <ImageContent src={studentPoseUrl} />}
              </Image>
            )}
            <BackgroundDisplay index={index} cartItem={cartItem} />
            {shouldShowPrice && <Price>{formatUSD(backgroundPrice)}</Price>}
          </SingleImageColumn>
        </ImagePairColumn>
      );
    });
  };

  const renderPoses = () => {
    return poses.map((pose, index) => {
      if (!pose) {
        return null;
      }
      let multiConfigChildProduct = null;
      if (isMultiConfig) {
        multiConfigChildProduct = cartItem.multiConfigChildProducts?.[index];
      }

      return (
        <ImagePairColumn key={`cart-background-option-${poses.id}-${index}`}>
          <SingleImageColumn>
            {multiConfigChildProduct && (
              <Name>{multiConfigChildProduct.productName}</Name>
            )}
            <Image
              onContextMenu={(event) => event.preventDefault()}
              style={{
                backgroundImage: `url("${poses[index].url}")`,
              }}
            />
          </SingleImageColumn>
        </ImagePairColumn>
      );
    });
  };

  const renderGeskusPreviewImageOnly = () => {
    if (cartItem?.posesCount > 0 && studentPreviewImageUrl) {
      return (
        <ImagePairColumn key={`cart-background-option-1`}>
          <SingleImageColumn>
            <Image
              onContextMenu={(event) => event.preventDefault()}
              style={{ backgroundImage: `url("${studentPreviewImageUrl}")` }}
            />
          </SingleImageColumn>
        </ImagePairColumn>
      );
    }

    return null;
  };

  const renderBackgroundsPoses = () => {
    if (cartItem.backgroundIds && cartItem.backgroundIds.length > 0) {
      return renderBackgrounds();
    }

    if (cartItem.poses && cartItem.poses.length > 0) {
      return renderPoses();
    }

    if (isGeskus && isPostEvent && studentPreviewImageUrl) {
      return renderGeskusPreviewImageOnly();
    }

    return null;
  };

  return <RowContainer>{renderBackgroundsPoses()}</RowContainer>;
};

export default CartItemBackgroundsPoses;
