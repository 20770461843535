import {
  ADD_STUDENT,
  SET_STUDENT_DATA,
  GET_STUDENTS,
  SET_SMS_PHONE,
  GET_MY_ORDERS,
  GET_PARENT_DATA,
  UPDATE_PARENT_ADDRESS_SUCCESS,
  UPDATE_PARENT_ADDRESS_FAILURE,
  UPDATE_PARENT_REQUEST,
  UPDATE_PARENT_SUCCESS,
  UPDATE_PARENT_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  SEND_EMAIL_CONFIRMATION_REQUEST,
  SEND_EMAIL_CONFIRMATION_SUCCESS,
  SEND_EMAIL_CONFIRMATION_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  ADD_STUDENT_SUCCESS,
  UPDATING_PARENT_DATA,
  VIEWED_COMPANY_MESSAGE,
  RESET_SUCCESS_MESSAGE,
} from '../actions/action_parent';

const INITIAL_STATE = {
  address: {},
  email: null,
  hasViewedCompanyMessage: false,
  firstName: null,
  loading: false,
  orders: null,
  phone: '',
  selectedStudentId: null,
  selectedStudentName: null,
  smsPhone: '',
  students: [],
  successMessage: '',
  needsConfirmation: false,
  unconfirmedEmail: '',
  hasCart: false,
  emailConfirmedAt: null,
  authProvider: null,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case GET_PARENT_DATA:
      if (action.payload.data) {
        const data = action.payload.data;
        return {
          ...state,
          address: data.address,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          smsPhone: data.smsPhone,
          publicToken: data?.publicToken,
          needsConfirmation: data.needsConfirmation,
          unconfirmedEmail: data.unconfirmedEmail,
          hasCart: data.hasCart,
          emailConfirmedAt: data.emailConfirmedAt,
          authProvider: data.authProvider,
        };
      } else {
        return state;
      }
    case GET_STUDENTS:
      if (action.payload.data) {
        return { ...state, students: action.payload.data };
      } else {
        return state;
      }
    case SET_STUDENT_DATA:
      return {
        ...state,
        selectedStudentId: action.payload.id,
        selectedStudentName: action.payload.name,
        selectedStudentFirstName: action.payload.firstName,
      };
    case ADD_STUDENT:
      return { ...state, students: [...state.students, action.payload.data] };
    case SET_SMS_PHONE:
      return { ...state, phone: action.payload, smsPhone: action.payload };
    case GET_MY_ORDERS:
      return { ...state, orders: action.payload.data };
    case UPDATE_PARENT_ADDRESS_SUCCESS:
      const addressData = action.payload;

      return {
        ...state,
        loading: false,
        address: {
          address1: addressData?.address_1 || addressData?.address1,
          address2: addressData?.address_2 || addressData?.address2,
          city: addressData?.city,
          state: addressData?.state,
          zipcode: addressData?.zipcode || addressData?.zip_code,
        },
        successMessage: 'Address updated successfully.',
      };
    case UPDATE_PARENT_REQUEST:
      return {
        ...state,
        loading: true,
        successMessage: '',
      };
    case UPDATE_PARENT_SUCCESS:
      const parentNameData = {
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
      };

      // Name format differences on backend
      delete action.payload.first_name;
      delete action.payload.last_name;

      // Students and address updated elsewhere
      delete action.payload.students;
      delete action.payload.address;

      return {
        ...state,
        ...action.payload,
        ...parentNameData,
        phone: action.payload.phone,
        smsPhone: action.payload.smsPhone,
        loading: false,
        successMessage: 'Account info updated successfully.',
      };
    case UPDATE_PARENT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: 'Password updated successfully.',
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_STUDENT_SUCCESS:
      return {
        ...state,
        students: [
          ...state.students,
          {
            id: action.payload.id,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
          },
        ],
        loading: false,
        successMessage: 'Student successfully added.',
      };
    case UPDATE_PARENT_ADDRESS_FAILURE:
      return { ...state, loading: false };
    case UPDATING_PARENT_DATA:
      return { ...state, loading: true };
    case RESET_SUCCESS_MESSAGE:
      return { ...state, successMessage: '' };
    case SEND_EMAIL_CONFIRMATION_REQUEST:
      return { ...state, successMessage: '' };
    case SEND_EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        unconfirmedEmail: action.payload.unconfirmedEmail,
        emailConfirmedAt: action.payload.emailConfirmedAt,
        successMessage: 'Please check your email for a confirmation link.',
      };
    case CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        successMessage: '',
      };
    case CONFIRM_EMAIL_SUCCESS:
      const payload = action.payload;

      return {
        ...state,
        email: payload.email,
        unconfirmedEmail: payload.unconfirmedEmail,
        needsConfirmation: payload.needsConfirmation,
        loading: false,
        successMessage: 'Your account has been confirmed.',
      };
    case CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: '',
      };
    case VIEWED_COMPANY_MESSAGE:
      return { ...state, hasViewedCompanyMessage: action.payload };
    default:
      return state;
  }
}
