import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import {
  updateParent,
  sendEmailConfirmation,
} from '../../../../actions/action_parent';

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const CancelLink = styled(Link)`
  display: inline-block;
  margin-left: 8px;
`;

const CustomerEmailEdit = ({ history, onSuccessRoute }) => {
  const dispatch = useDispatch();

  const { email, loading, unconfirmedEmail, authProvider } = useSelector(
    (state) => state.parent,
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [submissionError, setSubmissionError] = useState('');

  useEffect(() => {
    if (authProvider) {
      history.push(onSuccessRoute);
    }
  }, []);

  const onSubmit = async (data) => {
    const requestData = { unconfirmed_email: data.email };

    dispatch(sendEmailConfirmation(requestData))
      .then(() => {
        history.push(onSuccessRoute);
      })
      .catch((error) => {
        let errorMessages = '';
        if (error.response.data.errors) {
          error.response.data.errors.forEach((message) => {
            errorMessages += message;
          });
        }
        setSubmissionError(errorMessages);
      });
  };

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Edit Email</MainHeader>
        <Divider />
        <div className="order-checkout__main-content row">
          <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
            <Wrapper>
              <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors?.email ? 'is-invalid' : ''
                    }`}
                    id="email"
                    {...register('email', { required: 'Email is required' })}
                    defaultValue={unconfirmedEmail || email}
                  />
                  {errors.email && (
                    <div className="text-danger">{errors?.email.message}</div>
                  )}
                  {submissionError && (
                    <div className="text-danger">{submissionError}</div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary">
                  Update Email & Send Confirmation
                </button>
                <CancelLink to={onSuccessRoute} className="ml-2">
                  Cancel
                </CancelLink>
              </form>
            </Wrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerEmailEdit.propTypes = {
  onSuccessRoute: PropTypes.string,
};

CustomerEmailEdit.defaultProps = {
  onSuccessRoute: '/v3/checkout',
};
export default withRouter(CustomerEmailEdit);
