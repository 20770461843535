import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const Header1 = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #479de6;
`;

const StackedContactLine = styled.div`
  margin-top: 16px;
`;

const ContactData = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;

  @media (max-width: 767px) {
    margin-top: 4px;
  }
`;

const EditLinkStyled = styled(Link)`
  text-decoration: underline;
  color: #479de6;
  cursor: pointer;
  margin-top: 16px;
`;

const CheckoutEmailDisplay = ({
  email,
  unconfirmedEmail,
  emailConfirmedAt,
  needsConfirmation,
  authProvider,
}) => {
  return authProvider ? (
    <Wrapper>
      <Header1>Email</Header1>
      <>
        <p>
          {email} (authentication via{' '}
          {authProvider.charAt(0).toUpperCase() + authProvider.slice(1)})
        </p>
      </>
    </Wrapper>
  ) : (
    <Wrapper>
      <Header1>Email</Header1>
      <StackedContactLine>
        {unconfirmedEmail && emailConfirmedAt ? (
          <>
            <div className="alert alert-warning">
              An order confirmation will be sent to your current email unless
              you confirm your email change request. Check your email for a
              confirmation link.
            </div>
            <label>Current email</label>
            <p>{email}</p>
            <br />
            <label>Awaiting confirmation</label>
            <p>{unconfirmedEmail} (check this email for a link)</p>
            <EditLinkStyled to="/v3/checkout/customer-email-edit">
              edit email or resend confirmation
            </EditLinkStyled>
          </>
        ) : (
          <>
            {unconfirmedEmail ? (
              <>
                {needsConfirmation ? (
                  <div className="alert alert-warning">
                    Please confirm your email before ordering. Check your email
                    for a confirmation link.
                  </div>
                ) : (
                  <div className="alert alert-warning">
                    An order confirmation will be sent to your email. Please
                    click the link in your email to confirm your account.
                  </div>
                )}
                {unconfirmedEmail != email ? (
                  <>
                    <label>Email</label>
                    <p>{email}</p>

                    <label>Unconfirmed email</label>
                    <p>{unconfirmedEmail}</p>
                    <EditLinkStyled to="/v3/checkout/customer-email-edit">
                      edit email or resend confirmation
                    </EditLinkStyled>
                  </>
                ) : (
                  <>
                    <label>Email</label>
                    <p>{email}</p>
                    <EditLinkStyled to="/v3/checkout/customer-email-edit">
                      edit email or resend confirmation
                    </EditLinkStyled>
                  </>
                )}
              </>
            ) : (
              <>
                <p>Please check that your email address is correct.</p>
                <ContactData>{email}</ContactData>
                <EditLinkStyled to="/v3/checkout/customer-email-edit">
                  edit email
                </EditLinkStyled>
              </>
            )}
          </>
        )}
      </StackedContactLine>
    </Wrapper>
  );
};

export default CheckoutEmailDisplay;
